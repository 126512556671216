<template>
  <div data-v-d2e76338="" class="faq">
    <div data-v-d2e76338="" class="container"><h1 data-v-d2e76338="">F.A.Q и Поддержка</h1>
      <div data-v-d2e76338="" class="wrapper">
        <div data-v-d2e76338="" class="left">
          <div data-v-d2e76338="" class="requests">
            <div data-v-d2e76338="" class="list">
              <div data-v-d2e76338="" class="request" @click="tab = 'create'">
                <div data-v-d2e76338="" class="title">
                  <svg data-v-d2e76338="" width="22" height="22" viewBox="0 0 22 22" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path data-v-d2e76338=""
                          d="M10.9996 12.3916C4.38946 12.3916 0.749023 15.5187 0.749023 21.197C0.749023 21.6405 1.10846 21.9999 1.55194 21.9999H20.4473C20.8908 21.9999 21.2502 21.6405 21.2502 21.197C21.2503 15.519 17.6098 12.3916 10.9996 12.3916ZM2.38407 20.3941C2.69989 16.1485 5.59439 13.9975 10.9996 13.9975C16.4049 13.9975 19.2994 16.1485 19.6155 20.3941H2.38407Z"
                          fill="#FE6138"></path>
                    <path data-v-d2e76338=""
                          d="M10.9999 0C7.96346 0 5.67383 2.3357 5.67383 5.43284C5.67383 8.62069 8.06306 11.2139 10.9999 11.2139C13.9366 11.2139 16.3259 8.62069 16.3259 5.4331C16.3259 2.3357 14.0362 0 10.9999 0ZM10.9999 9.60828C8.94839 9.60828 7.27966 7.73532 7.27966 5.4331C7.27966 3.21544 8.84428 1.60583 10.9999 1.60583C13.1209 1.60583 14.72 3.25102 14.72 5.4331C14.72 7.73532 13.0513 9.60828 10.9999 9.60828Z"
                          fill="#FE6138"></path>
                  </svg>
                  <span data-v-d2e76338="">Создать тикет</span></div>
                <div data-v-d2e76338="" class="arrow">
                  <svg data-v-d2e76338="" width="9" height="16" viewBox="0 0 9 16" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path data-v-d2e76338=""
                          d="M1.99981 14.9999C1.76615 15.0004 1.53972 14.919 1.35981 14.7699C1.25855 14.686 1.17485 14.5829 1.11349 14.4665C1.05214 14.3502 1.01435 14.2229 1.00227 14.0919C0.990194 13.9609 1.00408 13.8289 1.04312 13.7033C1.08217 13.5777 1.1456 13.461 1.22981 13.3599L5.70981 7.99994L1.38981 2.62994C1.30674 2.52765 1.24471 2.40996 1.20728 2.28362C1.16985 2.15728 1.15775 2.02479 1.17169 1.89376C1.18563 1.76273 1.22533 1.63575 1.2885 1.52011C1.35168 1.40447 1.43708 1.30246 1.53981 1.21994C1.64327 1.1289 1.76444 1.06024 1.8957 1.01825C2.02696 0.976258 2.16549 0.961855 2.30258 0.97594C2.43967 0.990024 2.57238 1.03229 2.69236 1.1001C2.81234 1.1679 2.91701 1.25977 2.99981 1.36994L7.82981 7.36994C7.97689 7.54887 8.05729 7.77332 8.05729 8.00494C8.05729 8.23657 7.97689 8.46101 7.82981 8.63994L2.82981 14.6399C2.72949 14.761 2.60206 14.8566 2.45785 14.9192C2.31364 14.9817 2.15671 15.0094 1.99981 14.9999Z"
                          fill="white"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div data-v-d2e76338="" class="requests">
            <div data-v-d2e76338="" class="list">
              <div data-v-d2e76338="" class="request" @click="tab = 'all'">
                <div data-v-d2e76338="" class="title">
                  <svg data-v-d2e76338="" width="22" height="22" viewBox="0 0 22 22" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path data-v-d2e76338=""
                          d="M15.0836 6.20508H9.61993C9.13838 6.20508 8.74805 6.59536 8.74805 7.07696C8.74805 7.55851 9.13833 7.94884 9.61993 7.94884H15.0836C15.5655 7.94884 15.9555 7.55855 15.9555 7.07696C15.9555 6.59536 15.5652 6.20508 15.0836 6.20508Z"
                          fill="#636278"></path>
                    <path data-v-d2e76338=""
                          d="M17.1761 10.4185H9.61993C9.13838 10.4185 8.74805 10.8087 8.74805 11.2903C8.74805 11.7719 9.13833 12.1622 9.61993 12.1622H17.1761C17.6576 12.1622 18.0479 11.7719 18.0479 11.2903C18.0479 10.8087 17.6576 10.4185 17.1761 10.4185Z"
                          fill="#636278"></path>
                    <path data-v-d2e76338=""
                          d="M21.1281 0.741211H5.69615C5.2146 0.741211 4.82427 1.1315 4.82427 1.61309V9.83765H3.28402C1.47314 9.83765 0 11.3111 0 13.1217V17.975C0 19.7859 1.47314 21.259 3.28402 21.259C3.43221 21.259 3.57814 21.2492 3.72084 21.23H18.1057C20.2528 21.23 22 19.4831 22 17.3357V1.61309C22 1.1315 21.6097 0.741211 21.1281 0.741211ZM4.82432 17.975C4.82432 18.7222 4.28927 19.3471 3.58222 19.4862H3.28406V19.5153C2.43457 19.5153 1.74376 18.8242 1.74376 17.975V13.1217C1.74376 12.2725 2.43487 11.5814 3.28406 11.5814H4.82436V17.975H4.82432ZM20.2563 17.3357C20.2563 18.5014 19.2717 19.4862 18.1057 19.4862H6.19923C6.43491 19.0337 6.56803 18.5196 6.56803 17.975V2.48493H20.2563V17.3357Z"
                          fill="#636278"></path>
                    <path data-v-d2e76338=""
                          d="M15.0836 14.3716H9.61993C9.13838 14.3716 8.74805 14.7619 8.74805 15.2435C8.74805 15.725 9.13833 16.1153 9.61993 16.1153H15.0836C15.5655 16.1153 15.9555 15.7251 15.9555 15.2435C15.9555 14.7619 15.5652 14.3716 15.0836 14.3716Z"
                          fill="#636278"></path>
                  </svg>
                  <span data-v-d2e76338="">Мои тикеты</span></div>
                <div data-v-d2e76338="" class="arrow">
                  <svg data-v-d2e76338="" width="9" height="16" viewBox="0 0 9 16" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path data-v-d2e76338=""
                          d="M1.99981 14.9999C1.76615 15.0004 1.53972 14.919 1.35981 14.7699C1.25855 14.686 1.17485 14.5829 1.11349 14.4665C1.05214 14.3502 1.01435 14.2229 1.00227 14.0919C0.990194 13.9609 1.00408 13.8289 1.04312 13.7033C1.08217 13.5777 1.1456 13.461 1.22981 13.3599L5.70981 7.99994L1.38981 2.62994C1.30674 2.52765 1.24471 2.40996 1.20728 2.28362C1.16985 2.15728 1.15775 2.02479 1.17169 1.89376C1.18563 1.76273 1.22533 1.63575 1.2885 1.52011C1.35168 1.40447 1.43708 1.30246 1.53981 1.21994C1.64327 1.1289 1.76444 1.06024 1.8957 1.01825C2.02696 0.976258 2.16549 0.961855 2.30258 0.97594C2.43967 0.990024 2.57238 1.03229 2.69236 1.1001C2.81234 1.1679 2.91701 1.25977 2.99981 1.36994L7.82981 7.36994C7.97689 7.54887 8.05729 7.77332 8.05729 8.00494C8.05729 8.23657 7.97689 8.46101 7.82981 8.63994L2.82981 14.6399C2.72949 14.761 2.60206 14.8566 2.45785 14.9192C2.31364 14.9817 2.15671 15.0094 1.99981 14.9999Z"
                          fill="white"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-v-d2e76338="" class="right">
          <div data-v-d2e76338="" class="create" v-if="tab === 'create'">
            <div data-v-d2e76338="" class="go-back">
              <svg data-v-d2e76338="" width="29" height="29" viewBox="0 0 29 29" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path data-v-d2e76338="" fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.75 14.4997C21.75 14.74 21.6545 14.9705 21.4846 15.1405C21.3146 15.3105 21.0841 15.4059 20.8437 15.4059L10.3439 15.4059L14.2354 19.2956C14.3196 19.3798 14.3865 19.4798 14.4321 19.5899C14.4777 19.7 14.5011 19.818 14.5011 19.9372C14.5011 20.0563 14.4777 20.1743 14.4321 20.2844C14.3865 20.3945 14.3196 20.4945 14.2354 20.5788C14.1511 20.6631 14.0511 20.7299 13.941 20.7755C13.8309 20.8211 13.7129 20.8446 13.5938 20.8446C13.4746 20.8446 13.3566 20.8211 13.2465 20.7755C13.1364 20.7299 13.0364 20.6631 12.9521 20.5788L7.51463 15.1413C7.43023 15.0571 7.36327 14.9571 7.31758 14.847C7.2719 14.7369 7.24838 14.6189 7.24838 14.4997C7.24838 14.3805 7.2719 14.2624 7.31758 14.1523C7.36327 14.0422 7.43023 13.9422 7.51463 13.8581L12.9521 8.42055C13.0364 8.3363 13.1364 8.26946 13.2465 8.22386C13.3566 8.17826 13.4746 8.15478 13.5938 8.15478C13.7129 8.15478 13.8309 8.17826 13.941 8.22386C14.0511 8.26946 14.1511 8.3363 14.2354 8.42055C14.4055 8.59072 14.5011 8.82152 14.5011 9.06218C14.5011 9.18134 14.4777 9.29934 14.4321 9.40943C14.3865 9.51952 14.3196 9.61955 14.2354 9.70381L10.3439 13.5934L20.8437 13.5934C21.0841 13.5934 21.3146 13.6889 21.4846 13.8589C21.6545 14.0288 21.75 14.2593 21.75 14.4997Z"
                      fill="#FE6138"></path>
              </svg>
              <span data-v-d2e76338="">Создание тикета</span></div>
            <div data-v-d2e76338="" class="breadcrumbs">
              <svg data-v-d2e76338="" width="6" height="11" viewBox="0 0 6 11" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path data-v-d2e76338=""
                      d="M5.14856 10.9986C5.34715 10.9989 5.53961 10.9351 5.69252 10.8183C5.77859 10.7525 5.84973 10.6716 5.90188 10.5804C5.95402 10.4892 5.98615 10.3894 5.99641 10.2867C6.00667 10.184 5.99488 10.0805 5.96169 9.98202C5.9285 9.88355 5.87458 9.79208 5.80302 9.71286L1.99524 5.51075L5.66702 1.3008C5.73763 1.22061 5.79035 1.12834 5.82216 1.02929C5.85398 0.930246 5.86426 0.826376 5.85241 0.723653C5.84056 0.62093 5.80682 0.521379 5.75313 0.430723C5.69943 0.340066 5.62684 0.260092 5.53953 0.195396C5.45159 0.124026 5.34861 0.070192 5.23704 0.0372736C5.12547 0.00435519 5.00773 -0.00693696 4.89121 0.00410525C4.77469 0.0151475 4.6619 0.0482859 4.55992 0.101441C4.45794 0.154596 4.36898 0.22662 4.29861 0.312992L0.193353 5.01685C0.0683408 5.15713 0 5.33308 0 5.51467C0 5.69626 0.0683408 5.87222 0.193353 6.0125L4.4431 10.7163C4.52836 10.8112 4.63667 10.8862 4.75925 10.9353C4.88182 10.9843 5.01519 11.006 5.14856 10.9986Z"
                      fill="#FE6138"></path>
              </svg>
              <em @click="tab = ''" data-v-d2e76338="">FAQ</em><span data-v-d2e76338=""> | Создание тикета</span></div>
            <h2 data-v-d2e76338="">Создать тикет</h2><input data-v-d2e76338="" type="text" placeholder="Суть проблемы?"><textarea
              data-v-d2e76338="" placeholder="Подробно опишите чем Вам помочь.."></textarea>
            <button data-v-d2e76338="" class="send">Отправить</button>
          </div>
          <div data-v-d2e76338="" class="my-requests" v-if="tab === 'all'">
            <div data-v-d2e76338="" class="requests__head">
              <div data-v-d2e76338="" class="breadcrumbs">
                <svg data-v-d2e76338="" width="6" height="11" viewBox="0 0 6 11" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path data-v-d2e76338=""
                        d="M5.14856 10.9986C5.34715 10.9989 5.53961 10.9351 5.69252 10.8183C5.77859 10.7525 5.84973 10.6716 5.90188 10.5804C5.95402 10.4892 5.98615 10.3894 5.99641 10.2867C6.00667 10.184 5.99488 10.0805 5.96169 9.98202C5.9285 9.88355 5.87458 9.79208 5.80302 9.71286L1.99524 5.51075L5.66702 1.3008C5.73763 1.22061 5.79035 1.12834 5.82216 1.02929C5.85398 0.930246 5.86426 0.826376 5.85241 0.723653C5.84056 0.62093 5.80682 0.521379 5.75313 0.430723C5.69943 0.340066 5.62684 0.260092 5.53953 0.195396C5.45159 0.124026 5.34861 0.070192 5.23704 0.0372736C5.12547 0.00435519 5.00773 -0.00693696 4.89121 0.00410525C4.77469 0.0151475 4.6619 0.0482859 4.55992 0.101441C4.45794 0.154596 4.36898 0.22662 4.29861 0.312992L0.193353 5.01685C0.0683408 5.15713 0 5.33308 0 5.51467C0 5.69626 0.0683408 5.87222 0.193353 6.0125L4.4431 10.7163C4.52836 10.8112 4.63667 10.8862 4.75925 10.9353C4.88182 10.9843 5.01519 11.006 5.14856 10.9986Z"
                        fill="#FE6138"></path>
                </svg>
                <em @click="tab = ''" data-v-d2e76338="">Мои тикеты</em><span data-v-d2e76338="">| Ваши тикеты </span></div>
              <button @click="tab = 'create'" data-v-d2e76338="" class="create">
                <svg data-v-d2e76338="" width="17" height="17" viewBox="0 0 17 17" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path data-v-d2e76338=""
                        d="M14.8885 4.89155L9.89478 0.174051C9.77622 0.0622559 9.61983 0 9.45684 0H2.55059C2.19847 0 1.91309 0.285381 1.91309 0.6375V16.3625C1.91309 16.7146 2.19847 17 2.55059 17H14.4506C14.8027 17 15.0881 16.7146 15.0881 16.3625V5.355C15.0881 5.17969 15.0158 5.01201 14.8885 4.89155ZM13.8131 15.725H3.18809V1.275H9.20333L13.8131 5.62976V15.725Z"
                        fill="white"></path>
                  <path data-v-d2e76338=""
                        d="M11.4748 10.4126H5.3123C4.96019 10.4126 4.6748 10.698 4.6748 11.0501C4.6748 11.4022 4.96019 11.6876 5.3123 11.6876H11.4748C11.8269 11.6876 12.1123 11.4022 12.1123 11.0501C12.1123 10.698 11.8269 10.4126 11.4748 10.4126Z"
                        fill="white"></path>
                  <path data-v-d2e76338=""
                        d="M8.9248 7.86279H5.3123C4.96018 7.86279 4.6748 8.14817 4.6748 8.50029C4.6748 8.8524 4.96018 9.13778 5.3123 9.13778H8.9248C9.27691 9.13778 9.56229 8.8524 9.56229 8.50029C9.56229 8.14817 9.27691 7.86279 8.9248 7.86279Z"
                        fill="white"></path>
                  <path data-v-d2e76338=""
                        d="M11.4748 12.9624H5.3123C4.96019 12.9624 4.6748 13.2478 4.6748 13.5999C4.6748 13.952 4.96019 14.2374 5.3123 14.2374H11.4748C11.8269 14.2374 12.1123 13.952 12.1123 13.5999C12.1123 13.2478 11.8269 12.9624 11.4748 12.9624Z"
                        fill="white"></path>
                  <path data-v-d2e76338=""
                        d="M9.98691 4.88721V0.637207H8.71191V5.52471C8.71191 5.87683 8.9973 6.16221 9.34941 6.16221H14.4494V4.88721H9.98691Z"
                        fill="white"></path>
                </svg>
                СОЗДАТЬ ТИКЕТ
              </button>
            </div>
            <div data-v-d2e76338="" class="tickets"><h2 data-v-d2e76338="">Список тикетов</h2>
              <div data-v-d2e76338="" class="list">
                <div data-v-d2e76338="">Вы еще не создали ни одного тикета</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: ''
    }
  },
  created() {
    if (!this.$cookie.get('token')) {
      this.$root.isLoading = false
      return this.$router.back()
    }
  }
}
</script>