<template>
  <transition name="slide-fade">
    <div data-v-99c275b8="" ref="modal" class="modal deposit" style="" v-show="showModal">
      <div data-v-99c275b8="" ref="modal_body" class="body">
        <h2 data-v-99c275b8="">ПОПОЛНЕНИЕ БАЛАНСА</h2>
        <div @click="showModal = false" data-v-99c275b8="" class="close"><img data-v-99c275b8="" src="/img/close.svg"
            alt=""></div>
        <div data-v-99c275b8="" class="form">
          <div data-v-99c275b8="" class="input sum">
            <label data-v-99c275b8="" for="">Введите сумму</label>
            <input data-v-99c275b8="" type="number" v-model="value" placeholder="от 100₽">
          </div>
          <div data-v-99c275b8="" class="input promo">
            <label data-v-99c275b8="" for=""> Бонус-код (при наличии) </label>
            <input data-v-99c275b8="" type="text" v-model="promo" placeholder="Бонус-код">
          </div>
          <button data-v-99c275b8="" @click="setPayment()">ПОПОЛНИТЬ</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      i: "1",
      method: 'free-kassa',
      value: '100',
      promo: ''
    }
  },
  created() {
    this.$root.$on('showDepositModal', () => {
      this.showModal = true
    })
  },
  mounted() {
    const t = this

    this.$refs.modal.addEventListener("click", (function (e) {
      if (!t.$refs["modal_body"].contains(e.target)) {
        t.showModal = false
      }
    }), !1)
  },
  methods: {
    setPaymentMethod(i, method) {
      this.i = i.toString()
      this.method = method
    },
    setPayment() {
      if (this.i === "0" || this.method === '' || this.value === '' || this.value < 10) {
        return
      }

      this.$root.request(
        'POST',
        '/graphql',
        {
          "query": "mutation setPayment($i: String!, $method: String!, $value: Float!, $promo: String!) {setPayment(i: $i, method: $method, value: $value, promo: $promo)}",
          "variables": { "i": this.i, "method": this.method, "value": parseFloat(this.value), "promo": this.promo },
          "operationName": "setPayment"
        }
      ).then((data) => {
        window.location.href = data.setPayment
      }).catch(e => {
        this.$root.showNotify('Ошибка', e[0].message, 'error')
      })
    }
  }
}
</script>
